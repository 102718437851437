import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";

export const Styles = styled.div`
  .header-text {
    text-align: center;
    font-size: 40px;
    text-decoration: underline solid;
    padding-bottom: 50px;
  }
`;

const Contact = () => {
  return (
    <Styles>
      <div id="contact">
        <Helmet>
          <title>
            CloudVersity | Kubernetes, Docker, DevOps explained in detail |
            cloud-versity.com
          </title>
          <link rel="canonical" href="https://cloud-versity.com/portfolio" />
          <meta
            name="description"
            content="CloudVersity, Tutorials about Kubernetes, Docker, DevOps, Software, APM, Application Performance, Monitoring, cloud-versity.com, SRE, Site Reliability Engineer, Development, Microservice, K8s, Istio"
          ></meta>
        </Helmet>
        <h1 className="header-text">Contact</h1>
        <p>Serverless contact form will be available soon.</p>
        <p>
          Until then, please leave me a message via good old E-Mail:{" "}
          <a href="mailto:info@cloud-versity.com">info@cloud-versity.com</a>{" "}
        </p>
      </div>
    </Styles>
  );
};

export default Contact;
