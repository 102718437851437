import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import Icon, { Stack } from "@mdi/react";
import {
  mdiHexagon,
  mdiCloudCheck,
  mdiFileCodeOutline,
  mdiKubernetes,
  mdiRocket,
} from "@mdi/js";
import profile_hexagon from "./assets/profile_hexagon.png";

export const Styles = styled.div`
  svg {
    width: 150px;
    height: 150px;
  }

  .flex-container {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    padding-bottom: 50px;
  }

  .flex-item {
    text-align: center;
    color: black;
    flex-basis: 250px;
    padding-right: 20px;
    flex: auto;
  }

  .flex-item-profile {
    text-align: left;
    justify-content: center;
    flex-basis: 250px;
    padding-right: 20px;
    flex-grow: 0.8;
  }

  .flex-group {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 500px;
  }

  .header-text {
    text-align: center;
    font-size: 40px;
    text-decoration: underline solid;
    padding-bottom: 50px;
  }

  .subheader-text {
    text-align: center;
    padding-bottom: 50px;
  }

  .quote {
    border: 2px solid #1b242f;
    border-radius: 5px;
    padding: 5px;
    background: #00a1a7;
  }

  .highlight {
    color: #1b242f;
    font-weight: bold;
  }
  a {
    color: #00a1a7;
    font-weight: bold;
  }

  .flex-item img {
    flex: 0 0 auto;
    max-width: 100%;
    max-height: 100%;
    align-self: center;
    -webkit-align-self: center;
    margin: auto;
    object-fit: scale-down;
  }
`;

// "I help companies lower their cost of operations and increase app uptime/reliability leveraging my years of experience with DevOps, SRE and APM.  Read more about it below!"

const Home = () => {
  return (
    <Styles>
      <Helmet>
        <title>
          CloudVersity | Kubernetes, Docker, DevOps explained in detail |
          cloud-versity.com
        </title>
        <link rel="canonical" href="https://cloud-versity.com/portfolio" />
        <meta
          name="description"
          content="CloudVersity, Tutorials about Kubernetes, Docker, DevOps, Software, APM, Application Performance, Monitoring, cloud-versity.com, SRE, Site Reliability Engineer, Development, Microservice, K8s, Istio"
        ></meta>
      </Helmet>
      <link rel="canonical" href="https://cloud-versity.com/about" />
      <div id="about">
        <h1 className="header-text">ABOUT</h1>
        <div className="flex-container">
          <div className="flex-group">
            <div className="flex-item">
              <Stack>
                <Icon path={mdiHexagon} color="#00a1a7" size={1} />
                <Icon
                  path={mdiCloudCheck}
                  description="AWS, GCP, Azure"
                  title="Leverage from cloud platforms"
                  color="#efefef"
                  size={0.5}
                ></Icon>
              </Stack>
              <h4>Leverage the cloud</h4>
              <p>
                AWS, GCP or Azure doesn't matter but your should take a look at
                the versity of cloud offerings right know. Often the cloud is
                more cost-effective. Additionally using services and
                infrastructure within minutes will boost your development speed.
              </p>
            </div>
            <div className="flex-item">
              <Stack>
                <Icon path={mdiHexagon} color="#00a1a7" size={1} />
                <Icon
                  path={mdiKubernetes}
                  description="Scale up and down dependent on the load you receive"
                  title="Scale up with Kubernetes"
                  color="#efefef"
                  size={0.5}
                />
              </Stack>
              <h4>Scaleability</h4>
              <p>
                Say goodbye to your monolith, it is time for scalable
                microservice architectures thanks to Docker and the awesome
                container orchestrator Kubernetes.{" "}
              </p>
            </div>
          </div>
          <div className="flex-group">
            <div className="flex-item">
              <Stack>
                <Icon path={mdiHexagon} color="#00a1a7" size={1} />
                <Icon
                  path={mdiRocket}
                  description="Track your application performance and user journey with tools like Dynatrace or Prometheus"
                  title="Performance"
                  color="#efefef"
                  size={0.5}
                />
              </Stack>
              <h4>Application Performance</h4>
              <p>
                Full-Stack-Monitoring is the key for a successful application.
                Performance, Real-User-Monitoring and business critical KPIs
                determine your sales & profit.{" "}
              </p>
            </div>
            <div className="flex-item">
              <Stack>
                <Icon path={mdiHexagon} color="#00a1a7" size={1} />
                <Icon
                  path={mdiFileCodeOutline}
                  description="Possible candidates, GitLab CI/CD or GitHub Actions"
                  title="Start versioning your pipelines"
                  color="#efefef"
                  size={0.5}
                />
              </Stack>
              <h4>Pipeline as a Code</h4>{" "}
              <p>
                Whether GitLab CI/CD or GitHub Actions - having your pipeline
                poured in code and versioned will greatly improve readability
                and reduce the amount of wasted time for implementing changes to
                the deployment procedure.
              </p>
            </div>
          </div>
        </div>
        <div className="flex-container">
          <div className="flex-item-profile">
            <h2 className="subheader-text">Who is that guy?</h2>
            <p>
              I’m a DevOps & Site Reliability Engineer as well as an APM
              Consultant, focused on the Full-Stack-Monitoring solution
              Dynatrace.{" "}
            </p>
            <p>
              I love to work with the newest technologies therefore Docker,
              Kubernetes, unbreakable deployment pipelines and monitoring are my
              daily business. I like to be at the customer side for consulting
              purposes but also to get insights of how problems get tackled in
              other companies.
            </p>
            <p>
              Currently I’m working for the <a href="avodaq.com">avodaq AG</a> –
              a world-wide acting system integrator &{" "}
              <a href="cisco.com">Cisco</a> partner with a young software
              development department which is evolving quickly.
            </p>{" "}
            <p>
              Besides that, I'm really into fitness. My personal challenge is
              running a marathon until the end of 2021. Diving and snowboarding
              are also hobbies I really enjoy.
            </p>
          </div>
          <div className="flex-item">
            <img
              src={profile_hexagon}
              alt="Stephan Dannewitz, DevOps & Site Reliability Engineer"
            ></img>
          </div>
        </div>
      </div>
    </Styles>
  );
};

export default Home;

/*
            <p className="quote">
              <span className="highlight">
                There is always something you can learn and there is always more
                than one solution!
              </span>
            </p>
            */
