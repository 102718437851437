import CookieConsent from "react-cookie-consent";
import React from "react";
import ReactGA from "react-ga";

const activateGA = () => {
  ReactGA.initialize("UA-165737172-1");
  ReactGA.ga("set", "anonymizeIp", true);
  console.log("GA activated!");
  ReactGA.pageview(window.location.pathname + window.location.search);
  console.log(window.location.pathname);
};

const CookieConsentBanner = () => {
  return (
    <CookieConsent
      location="top"
      buttonText="Accept"
      enableDeclineButton
      declineButtonText="Decline"
      flipButtons
      cookieName="cloud-versity-consent"
      style={{
        background: "#2B373B",
        opacity: "0.8",
        alignItems: "center",
        justifyContent: "center",
      }}
      buttonStyle={{
        background: "#efefef",
        color: "#2B373B",
        fontSize: "14px",
      }}
      declineButtonStyle={{
        background: "#efefef",
        color: "#2B373B",
        fontSize: "14px",
      }}
      expires={150}
      onAccept={activateGA}
    >
      <span style={{ fontSize: "14px", color: "#efefef" }}>
        This website uses cookies to enhance the user experience and only
        essential cookies are enabled by default. Please accept additional
        cookies if you are okay with anonymized tracking to help me improve the
        page. To find out more, look at the{" "}
        <a href="/privacy-policy#privacy-policy">privacy policy</a>.
      </span>
    </CookieConsent>
  );
};

export default CookieConsentBanner;
