import React from "react";

const PrivacyPolicy = () => {
  return (
    <div id="imprint">
      <h2>Imprint</h2>
      <p>
        Stephan Dannewitz <br></br>
      10249 Berlin <br></br>
      Germany <br></br> <br></br>
      Phone: 03047390151 <br></br>
      Email: <a href="mailto:info@cloud-versity.com">
          info@cloud-versity.com
      </a>{" "}
        <br></br>
      Website: <a href="www.cloud-versity.com">www.cloud-versity.com</a> <p></p>
      </p>
    </div>
  )
};

export default PrivacyPolicy;
