import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ReactGA from "react-ga";
import { Cookies } from "react-cookie-consent";
import CookieConsentBanner from "./components/CookieConsentBanner";

import About from "./About";
import Portfolio from "./Portfolio";
import Contact from "./Contact";
import PrivacyPolicy from "./PrivacyPolicy";
import NoMatch from "./NoMatch";
import Layout from "./components/Layout";
import NavigationBar from "./components/NavigationBar";
import Jumbotron from "./components/Jumbotron";
import Footer from "./components/Footer";
import FooterCopyright from "./components/FooterCopyright";
import Imprint from "./Imprint"

const App = () => {
  useEffect(() => {
    let isCookieSet = Cookies.get("cloud-versity-consent");
    if (isCookieSet) {
      ReactGA.initialize("UA-165737172-1");
      ReactGA.ga("set", "anonymizeIp", true);
      ReactGA.pageview(window.location.pathname + window.location.search);
      console.log(window.location.pathname);
    }
  }, []);
  return (
    <div className="App">
      <React.Fragment>
        <Jumbotron />
        <NavigationBar />
        <Layout>
          <Router>
            <Switch>
              <Route exact path="/" component={About} />
              <Route exact path="/about" component={About} />
              <Route exact path="/portfolio" component={Portfolio} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Route exact path="/imprint" component={Imprint} />
              <Route component={NoMatch} />
            </Switch>
          </Router>
        </Layout>
        <Footer />
        <FooterCopyright />
        <CookieConsentBanner />
      </React.Fragment>
    </div>
  );
};

export default App;
