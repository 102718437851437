import React from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import Icon from "@mdi/react";
import {
  mdiKubernetes,
  mdiGitlab,
  mdiGithub,
  mdiTerraform,
  mdiDocker,
  mdiAws,
  mdiGoogleCloud,
  mdiSlack,
  mdiLanguagePython,
  mdiMicrosoftVisualStudioCode,
  mdiMicrosoftAzure,
  mdiLanguageJava,
} from "@mdi/js";

export const Styles = styled.div`
  .flex-container {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
    flex-grow: 1;
  }

  .flex-group {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 600px;
    align-items: center;
    justify-content: center;
  }
  @media only screen and (min-width: 600px) {
    .flex-group {
      flex-basis: 800px;
    }
  }

  .flex-item {
    text-align: center;
    padding: 10px;
  }

  .header-text {
    text-align: center;
    font-size: 40px;
    text-decoration: underline solid;
    padding-bottom: 50px;
  }
`;
const Portfolio = () => {
  return (
    <Styles>
      <Helmet>
        <title>
          CloudVersity | Kubernetes, Docker, DevOps explained in detail |
          cloud-versity.com
        </title>
        <link rel="canonical" href="https://cloud-versity.com/portfolio" />
        <meta
          name="description"
          content="CloudVersity, Tutorials about Kubernetes, Docker, DevOps, Software, APM, Application Performance, Monitoring, cloud-versity.com, SRE, Site Reliability Engineer, Development, Microservice, K8s, Istio"
        ></meta>
      </Helmet>
      <div id="portfolio">
        <h1 className="header-text">TECH STACK</h1>
        <div className="flex-container">
          <div className="flex-group">
            <div className="flex-item">
              <Icon
                path={mdiKubernetes}
                color="#00a1a7"
                description="A container orchastrator"
                title="Kubernetes"
                size={4}
              />
            </div>
            <div className="flex-item">
              <Icon
                path={mdiDocker}
                color="#00a1a7"
                description="A container engine running (parts of) your application"
                title="Docker"
                size={4}
              />
            </div>
            <div className="flex-item">
              <Icon
                path={mdiAws}
                color="#00a1a7"
                description="Amazon Web Services"
                title="AWS"
                size={4}
              />
            </div>
            <div className="flex-item">
              <Icon
                path={mdiGoogleCloud}
                color="#00a1a7"
                description="Google Cloud Platform"
                title="GCP"
                size={4}
              />
            </div>
            <div className="flex-item">
              <Icon
                path={mdiMicrosoftAzure}
                color="#00a1a7"
                description="Microsft Azure Cloud"
                title="Azure"
                size={4}
              />
            </div>
            <div className="flex-item">
              <Icon
                path={mdiGitlab}
                color="#00a1a7"
                description="Source code management software which includes CI/CD."
                title="GitLab"
                size={4}
              />
            </div>
          </div>
          <div className="flex-group">
            <div className="flex-item">
              <Icon
                path={mdiGithub}
                color="#00a1a7"
                description="Source code management platform"
                title="GitHub"
                size={4}
              />
            </div>
            <div className="flex-item">
              <Icon
                path={mdiTerraform}
                color="#00a1a7"
                description="Perfect for creating and manage infrastruce - IaaS "
                title="HashiCorp Terraform"
                size={4}
              />
            </div>
            <div className="flex-item">
              <Icon
                path={mdiLanguagePython}
                color="#00a1a7"
                description="A cool programming language"
                title="Python"
                size={4}
              />
            </div>
            <div className="flex-item">
              <Icon
                path={mdiLanguageJava}
                color="#00a1a7"
                description="A wide spread programming language"
                title="Java"
                size={4}
              />
            </div>
            <div className="flex-item">
              <Icon
                path={mdiMicrosoftVisualStudioCode}
                color="#00a1a7"
                description="A great lightwight IDE"
                title="Visual Studio Code"
                size={4}
              />
            </div>
            <div className="flex-item">
              <Icon
                path={mdiSlack}
                color="#00a1a7"
                description="A famous communication platform for instant messaging which can also be used for webhooks etc."
                title="Slack"
                size={4}
              />
            </div>
          </div>
        </div>
        <h1 className="header-text">Portfolio</h1>
        <div>
          <p>A list of my projects will be available soon.</p>
        </div>
      </div>
    </Styles>
  );
};

export default Portfolio;
