import React from "react";
import { Jumbotron as Jumbo, Container } from "react-bootstrap";
import styled from "styled-components";
import digital_background from "../assets/digital_background_small.jpg";

const Styles = styled.div`
  .jumbotron {
    background: url(${digital_background}) no-repeat center;
    background-size: cover;
    color: #efefef;
    position: relative;
    z-index: -2;
    height: 100vh;
    text-align: center;
    margin-bottom: 0px;
  }

  .overlay {
    background-color: #000;
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }

  .overlayText {
    min-height: 100vh;
    display: flex;
    align-items: center;
  }

  .highlight {
    color: #e31b6d;
  }
`;

const Jumbotron = () => {
  return (
    <Styles>
      <Jumbo fluid className="jumbotron overlayText">
        <div className="overlay"></div>
        <Container>
          <h1 className="h1">
            Hello, I'm <span className="highlight">Stephan Dannewitz</span>,
            <p></p> a DevOps and SRE.
          </h1>
          <p>
            <br></br>
            I'm a cloud-native and automation is my duty! <br></br> Further I
            love APM - let me hunt down your root causes with Dynatrace.
          </p>
        </Container>
      </Jumbo>
    </Styles>
  );
};

export default Jumbotron;
