import React from "react";
import { Navbar, Container, Card } from "react-bootstrap";
import styled from "styled-components";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";

library.add(fab);

const Styles = styled.div`
  .navbar {
    background-color: #1b242f;
    border-top: 3px solid #04c2c9;
    margin-bottom: 0px;
    padding: 30px;
  }

  .card {
    padding: 15px;
    background-color: #262f38;
    color: #efefef;
    transition: 0.5s;
    margin: 0 15px;
  }

  .card:hover {
    transform: rotate(45deg);
    color: #e31b6d;
    transition: 0.5s;
  }

  .social {
    align-items: center;
    display: flex;
    justify-content: center;
  }
`;

const Footer = () => {
  return (
    <Styles>
      <Navbar expand="lg">
        <Container className="social">
          <a
            href="https://www.linkedin.com/in/stephan-dannewitz-3663a9140"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Card>
              <FontAwesomeIcon
                icon={["fab", "linkedin-in"]}
                size="1x"
              ></FontAwesomeIcon>
            </Card>
          </a>
          <a
            href="https://www.xing.com/profile/Stephan_Dannewitz/cv"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Card>
              <FontAwesomeIcon icon={["fab", "xing"]} size="1x" />
            </Card>
          </a>
          <a
            href="https://www.facebook.com/stephan.dannewitz.3"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Card>
              <FontAwesomeIcon icon={["fab", "facebook-f"]} size="1x" />
            </Card>
          </a>
          <div className="w-100 d-lg-none">
            <p></p>
          </div>
          <a
            href="https://twitter.com/cloud_versity"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Card>
              <FontAwesomeIcon icon={["fab", "twitter"]} size="1x" />
            </Card>
          </a>
          <a
            href="https://www.instagram.com/cloudversity"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Card>
              <FontAwesomeIcon icon={["fab", "instagram"]} size="1x" />
            </Card>
          </a>
          <a
            href="https://www.youtube.com/channel/UCrP3whO-9zR7T9jq7d9eSCw"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Card>
              <FontAwesomeIcon icon={["fab", "youtube"]} size="1x" />
            </Card>
          </a>
        </Container>
      </Navbar>
    </Styles>
  );
};

export default Footer;
