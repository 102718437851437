import React from "react";

const NoMatch = () => {
  return (
    <div id="NoMatch">
      <h2>No Match</h2>
    </div>
  );
};

export default NoMatch;
