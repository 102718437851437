import React from "react";
import { Navbar, Container } from "react-bootstrap";
import styled from "styled-components";

const Styles = styled.div`
  .navbar {
    background-color: #131a26;
    margin-bottom: 0px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .copyright {
    align-items: center;
    display: flex;
    justify-content: center;
    color: #d7d7d7;
  }
  a {
    padding-right: 15px;
    color: #d7d7d7;
  }
  span {
    padding-right: 15px;
  }
`;

const FooterCopyright = () => {
  return (
    <Styles>
      <Navbar expand="lg">
        <Container className="copyright">
          <span>© 2020 Copyright</span>
          <a href="/privacy-policy#privacy-policy">Privacy policy</a>
          <a href="/imprint#imprint">Imprint</a>
        </Container>
      </Navbar>
    </Styles>
  );
};

export default FooterCopyright;
